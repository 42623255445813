module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ariel-chait-com","short_name":"ariel-chait","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/photo-icon.png","cache_busting_mode":"name","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c992e54b3f855baae8ddd7f7e1902191"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TNR6RSZ","includeInDevelopment":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
